import AvatarImg from "@/components/AvatarImg.vue";
import BadgePlan from "@/components/BadgePlan.vue";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import UserCertificatesDownload from "@/components/JobApplicant/UserCertificatesDownload.vue";
import PreviewModal from "@/components/PreviewModal.vue";
import { CompanySubscriptionSaveAct, CompanySubscriptionStatusEnum, Default, ImageLink, } from "@/enums";
import companySubscriptionHttp from "@/http/companySubscription";
import { baseModal, helperMixin, saveAndUpdate } from "@/mixins";
import moment from "moment";
import { defineComponent } from "vue";
import SubscriptionPlanLabel from "@/components/SubscriptionPlanLabel.vue";
export default defineComponent({
    components: {
        SubscriptionPlanLabel,
        BadgePlan,
        PreviewModal,
        BaseModal,
        BaseFormGroup,
        BaseButtonDanger,
        BaseButtonPrimary,
        BaseButtonDefault,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        UserCertificatesDownload,
        AvatarImg,
    },
    mixins: [
        helperMixin,
        baseModal,
        saveAndUpdate(companySubscriptionHttp()),
    ],
    props: {
        company: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            previewModal: false,
            CompanySubscriptionStatusEnum,
            loading: false,
        };
    },
    computed: {
        ImageLink() {
            return ImageLink;
        },
        Default() {
            return Default;
        },
        updateId() {
            var _a, _b;
            return (_b = (_a = this.company) === null || _a === void 0 ? void 0 : _a.subscription) === null || _b === void 0 ? void 0 : _b.id;
        },
        redirectAfterSubmit() {
            return false;
        },
    },
    methods: {
        onShow() {
            this.setup();
        },
        setForm() {
            this.form.act = CompanySubscriptionSaveAct.Extend;
            this.form.exp_date = moment().add(2, "months").format("yyyy-MM-DD");
        },
        afterSubmit() {
            this.value = false;
        },
        handleSubmit(act) {
            this.form.payment_approve_status = act;
            this.submit();
        },
    },
});
