import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";
export default defineComponent({
    components: { BaseButtonPrimary, BaseButtonDefault },
    mixins: [helperMixin],
    data() {
        return {
            contentModalContainer: false,
        };
    },
    async mounted() {
        await this.$nextTick();
        this.contentModalContainer = document.getElementById("contentModalContainer")
            ? true
            : false;
    },
    props: {
        isSubmitting: {
            type: Boolean,
            default: false,
        },
        withCancelButton: {
            type: Boolean,
            default: true,
        },
        withSaveButton: {
            type: Boolean,
            default: true,
        },
    },
});
